import { useState, useCallback, useEffect } from 'react'
import { nanoid } from 'nanoid'
import { useDispatch, useSelector } from 'react-redux'

import { getFromArrayMinValueIndex, insertAtPosition } from 'src/utils/arrays'
import { useMapStudio } from 'src/pages/MapStudio/controllers/MapStudioController'
import { useMap } from 'src/modules/map'
import { reduceRoutes } from 'src/pages/MapStudio/controllers/reduceRoutes'
import { useBuildingRoute } from 'src/hooks/useBuildingRoute'
import { useKeyPress } from 'src/hooks/useKeyPress'
import { loadLayers } from 'src/pages/MapStudio/features/routes/loadLayers'
import useRouteNavigationArrows from 'src/pages/MapStudio/features/routes/useRouteNavigationArrows'

import { getNeighbors, routeActions } from 'src/redux/slicers/routes'

import { UseVirtualNeighbors } from 'src/pages/MapStudio/features/routes/virtualNeighbors'
import { defaultDrawingLineFeatures } from './DrawRoutesConstants'

const turf = require('@turf/turf')

export const useRoute = ({ isInitiated }) => {
  const {
    setStartAddWay,
    routeGeojson,
    setStartAddPoi,
    setBuildingRoute,
    buildingRoute,
    startAddElevator,
    setStartAddElevator,
    startAddStairs,
    setStartAddStairs,
    showRoutes,
    setShowRoutes,
    setWayWasAdded,
    stopRouteDraw,
    currentRoute,
    setCurrentRoute,
    isShowDirection,
    isShowPointId,
    activeTool,
  } = useMapStudio()

  const {
    loadRouteNavigation,
    cleanPointsId,
    cleanRouteNavigation,
    showPointsId,
    createDirectionArrowPoint,
  } = useRouteNavigationArrows()

  const { map: currentMap, mapDraw } = useMap()
  const { get: getBuildingRoute } = useBuildingRoute()

  const { setActiveDrawTool } = routeActions

  const neighborsFromServer = useSelector(getNeighbors)

  const dispatch = useDispatch()

  const {
    addNewVirtualPoint,
    removeVirtualPoint,
    linkVirtualEndLinePoint,
    updateVirtualPointProperty,
    updateNeighborsAfterLineBreak,
    linkExistedLineEndPoint,
  } = UseVirtualNeighbors({ currentRoute })

  const enterKey = useKeyPress('Enter')
  const escapeKey = useKeyPress('Escape')
  const backspaceKey = useKeyPress('Backspace')
  const deleteKey = useKeyPress('Delete')
  const shiftHoldEvent = useKeyPress('Shift')

  const [drawingLineGeojson] = useState({
    type: 'FeatureCollection',
    features: defaultDrawingLineFeatures,
  })
  const [routeMultiLine] = useState(
    routeGeojson.features.find(item => item.geometry.type === 'MultiLineString')
  )
  const [lastPoint] = useState({})

  const [drawingLineGeometry] = useState(
    drawingLineGeojson.features.find(
      item => item.geometry.type === 'LineString'
    ).geometry
  )
  const [drawingPointGeometry] = useState(
    drawingLineGeojson.features.find(item => item.geometry.type === 'Point')
      .geometry
  )

  useEffect(() => {
    if (currentMap && isInitiated) {
      if (isShowPointId.value) {
        showPointsId({
          currentMap,
          coordinates: routeGeojson.features[0].geometry.coordinates,
          buildingRoute: currentRoute.virtualNeighbors,
        })
      } else {
        cleanPointsId({ currentMap })
      }
    }
  }, [isShowPointId.value])

  useEffect(() => {
    if (currentMap && isInitiated) {
      if (isShowDirection.value) {
        loadRouteNavigation({
          currentMap,
          coordinates: routeGeojson.features[0].geometry.coordinates,
          buildingRoute: currentRoute.virtualNeighbors,
        })
      } else {
        cleanRouteNavigation({ currentMap })
      }
    }
  }, [isShowDirection.value])

  useEffect(() => {
    if (!currentRoute.virtualNeighbors?.length && neighborsFromServer.length) {
      currentRoute.virtualNeighbors = neighborsFromServer
    }

    if (currentMap && isInitiated && currentRoute.virtualNeighbors?.length) {
      if (isShowDirection.value) {
        loadRouteNavigation({
          currentMap,
          coordinates: routeGeojson.features[0].geometry.coordinates,
          buildingRoute: currentRoute.virtualNeighbors,
        })
      }
    }
  }, [
    neighborsFromServer,
    setCurrentRoute,
    currentMap,
    currentRoute,
    isShowPointId.value,
    isShowDirection.value,
    currentRoute.virtualNeighbors?.length,
  ])

  useEffect(() => {
    const load = async () => {
      const route = await getBuildingRoute()
      setBuildingRoute(route)
    }

    load()

    return () => {
      if (!currentMap) return false

      currentMap.off('click', handleClickOnMap)
      currentMap.off('mousemove', handleMoveOnMap)
    }
  }, [])

  useEffect(() => {
    if (isInitiated && buildingRoute) {
      currentRoute.virtualNeighbors = buildingRoute
      reduceRoutes({
        routes: buildingRoute,
        routeGeojson,
        createDirectionArrowPoint
      })
      loadLayers({
        currentMap,
        routeGeojson,
        drawingLineGeojson,
      })

      if (isShowDirection.value) {
        loadRouteNavigation({
          currentMap,
          coordinates: routeGeojson.features[0].geometry.coordinates,
          buildingRoute,
        })
      }

      currentMap.on('click', handleClickOnMap)
      currentMap.on('mousemove', handleMoveOnMap)
    }
  }, [isInitiated, buildingRoute])

  useEffect(() => {
    if (currentMap && currentMap.startDrawWaypoint) {
      if (escapeKey?.status) {
        cancelAddWayPoint()
      } else {
        stopDrawLine()
        updateRouteSource()
        updateDrawSource()
      }
    }
  }, [enterKey, escapeKey])

  useEffect(() => {
    if (currentMap && currentMap.startDrawWaypoint && lastPoint.id) {
      removePoint(lastPoint.id)
      stopDrawLine()
      updateRouteSource()
      updateDrawSource()
    }
  }, [backspaceKey, deleteKey])

  useEffect(() => {
    if (!isInitiated) return false

    if (routeGeojson && routeGeojson.features) {
      const mapSource = currentMap.getSource('nearmotion-routesGeoJson')
      if (mapSource) {
        mapSource.setData(routeGeojson)
      }
    }
  }, [routeGeojson, isInitiated])
  useEffect(() => {
    if (stopRouteDraw) {
      cancelAddWayPoint()
    }
  }, [stopRouteDraw, currentMap, mapDraw])

  useEffect(() => {
    if (!isInitiated) return false

    const layers = [
      'nearmotion-routes-temp-lines',
      'nearmotion-routes-temp-points',
      'nearmotion-routes-lines',
      'nearmotion-routes-points',
    ]

    if (currentMap && currentMap.getLayer(layers[0])) {
      layers.forEach(layer => {
        currentMap.setLayoutProperty(
          layer,
          'visibility',
          showRoutes ? 'visible' : 'none'
        )
      })
    }
  }, [showRoutes, isInitiated])

  useEffect(() => {
    window.shiftHoldEvent = shiftHoldEvent?.status
  }, [shiftHoldEvent?.status])

  const updateRouteSource = useCallback(() => {
    const source = currentMap.getSource('nearmotion-routesGeoJson')
    if (source) {
      source.setData(routeGeojson)
    }
  }, [currentMap, routeGeojson])

  const updateDrawSource = useCallback(() => {
    const source = currentMap.getSource('nearmotion-drawingLineGeojson')
    if (source) {
      source.setData(drawingLineGeojson)
    }
  }, [currentMap, drawingLineGeojson])

  // -------- ON CLICK START ----------
  const removePointActive = useCallback(() => {
    const activePoint = routeGeojson.features.find(
      point => point.properties.active
    )

    if (activePoint) {
      activePoint.properties.active = false
    }
  }, [routeGeojson])

  const setPointProperty = useCallback(
    (pointId, key, value) => {
      routeGeojson.features.forEach(point => {
        if (point.properties.id === pointId) {
          point.properties[key] = value
        }
      })
    },
    [routeGeojson]
  )

  const getCurrentIndex = useCallback(() => {
    const currentMsNeighbors = routeMultiLine.properties.msNeighbors
    const lineIndex = currentMsNeighbors.findIndex(item => item.length === 1)

    return lineIndex < 0 ? currentMsNeighbors.length : lineIndex
  }, [routeMultiLine])

  const getNearestPointOnLine = useCallback(
    currentCoordinates => {
      return turf.nearestPointOnLine(
        routeMultiLine.geometry,
        currentCoordinates
      ).geometry.coordinates
    },
    [routeMultiLine]
  )

  const addLinePointNeighbors = useCallback(
    pointId => {
      const lineIndex = getCurrentIndex()
      const currentMsNeighbors = routeMultiLine.properties.msNeighbors
      if (!currentMsNeighbors[lineIndex]) {
        currentMsNeighbors[lineIndex] = []
      }

      currentMsNeighbors[lineIndex].push({
        line: lineIndex,
        point: pointId,
      })

      return lineIndex
    },
    [routeMultiLine, getCurrentIndex]
  )

  const modifyPointType = useCallback(
    pointId => {
      setPointProperty(pointId, 'kind', lastPoint.type)

      updateVirtualPointProperty({
        pointId,
        valueToUpdate: lastPoint.type,
        propertyName: 'kind',
        currentRoute,
      })

      lastPoint.type = false
      // setStartAddElevator(false)
      setStartAddWay(true)
    },
    [
      currentRoute,
      lastPoint,
      setPointProperty,
      setStartAddWay,
      updateVirtualPointProperty,
    ]
  )

  const addLine = useCallback(
    (pointCoordinates, lineIndex, isOnLine) => {
      const { coordinates } = routeMultiLine.geometry
      // check line
      if (!coordinates[lineIndex]) {
        coordinates[lineIndex] = []
      }
      coordinates[lineIndex].push(pointCoordinates)
      drawingLineGeometry.coordinates = [pointCoordinates]
      const lineLength = coordinates[lineIndex].length
      if (lineLength > 1 && lastPoint.id && !isOnLine) {
        addLine(pointCoordinates, lineIndex + 1)
        addLinePointNeighbors(lastPoint.id)
        setPointProperty(lastPoint.id, 'connecting', true)
      }
    },
    [
      routeMultiLine?.geometry,
      drawingLineGeometry,
      lastPoint,
      addLinePointNeighbors,
      setPointProperty,
    ]
  )

  const createPoint = useCallback(
    (lastCoord, isOnLine) => {
      const pointId = nanoid(10)

      const currentPoint = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: lastCoord,
        },
        properties: {
          id: pointId,
          pointCoordinates: lastCoord.toString() + pointId, // [point.longitude, point.latitude].toString(),
        },
      }

      if (lastPoint.type) {
        modifyPointType(pointId)
      }
      lastPoint.id = pointId
      const newLineIndex = addLinePointNeighbors(pointId)

      addLine(lastCoord, newLineIndex, isOnLine)
      // console.log('added new point: ', pointId)

      const currentMsNeighbors =
        routeMultiLine.properties.msNeighbors[newLineIndex]

      const isOneWay = activeTool.value === 'oneWay'
      const pointNeighbors =
        (isOneWay && currentMsNeighbors.length > 0) || !isOneWay
          ? currentMsNeighbors
          : []

      currentPoint.properties.hasNeighbors = Boolean(pointNeighbors.length)

      console.log('point neighbors', pointNeighbors)
      routeGeojson.features.push(currentPoint)

      const addPointParameters = {
        currentPoint,
        currentRoute,
        currentMsNeighbors,
        lastPoint,
        setCurrentRoute,
      }
      if (isOneWay) {
        // add only one neighbor for the last point
        addPointParameters.currentMsNeighbors = pointNeighbors
      }

      addNewVirtualPoint(addPointParameters)

      return pointId
    },
    [
      lastPoint,
      setCurrentRoute,
      addLinePointNeighbors,
      addLine,
      routeMultiLine.properties.msNeighbors,
      currentRoute,
      routeGeojson.features,
      addNewVirtualPoint,
      modifyPointType,
    ]
  )

  const checkExistingLine = useCallback(
    (lastPointId, currentPointId) => {
      const {
        properties: { msNeighbors },
      } = routeMultiLine

      return msNeighbors.some(line => {
        if (line.length > 1) {
          const findLine =
            (line[0].point === lastPointId ||
              line[0].point === currentPointId) &&
            (line[1].point === lastPointId || line[1].point === currentPointId)
          if (findLine) {
            return findLine
          }
        }

        return false
      })
    },
    [routeMultiLine]
  )
  const linkPoints = useCallback(
    currentPoint => {
      if (lastPoint.id && currentPoint.properties) {
        const lineIsExist = checkExistingLine(
          lastPoint.id,
          currentPoint.properties.id
        )

        if (lineIsExist) {
          // TODO: Update points to have correct direction

          linkExistedLineEndPoint({
            currentPoint,
            currentRoute,
            lastPoint,
          })

          lastPoint.id = currentPoint.properties.id

          const lastCoord = currentPoint.geometry.coordinates
          const newLineIndex = addLinePointNeighbors(lastPoint.id)
          addLine(lastCoord, newLineIndex)
          setPointProperty(lastPoint.id, 'connecting', true)

          drawingLineGeometry.coordinates[0] = currentPoint.geometry.coordinates

          return false
        }
      }

      const pointId = currentPoint.properties.id
      lastPoint.id = pointId

      console.log('link points by', pointId)

      const lastCoord = currentPoint.geometry.coordinates
      const newLineIndex = addLinePointNeighbors(pointId)
      addLine(lastCoord, newLineIndex)

      if (lastPoint.type) {
        modifyPointType(pointId)
      }
      setPointProperty(pointId, 'connecting', true)
      drawingLineGeometry.coordinates[0] = lastCoord

      const currentMsNeighbors =
        routeMultiLine.properties.msNeighbors[newLineIndex]

      // update neighbor for the last and the first points
      if (currentMsNeighbors.length > 1) {
        linkVirtualEndLinePoint({
          lastPoint,
          currentPoint,
          currentRoute,
          currentMsNeighbors,
          setCurrentRoute,
        })
      }
    },
    [
      lastPoint,
      addLinePointNeighbors,
      addLine,
      setPointProperty,
      drawingLineGeometry.coordinates,
      routeMultiLine.properties.msNeighbors,
      checkExistingLine,
      linkExistedLineEndPoint,
      currentRoute,
      modifyPointType,
      linkVirtualEndLinePoint,
      setCurrentRoute,
    ]
  )

  const getNearestLinePoint = useCallback(
    pointCoordinates => {
      const {
        geometry: { coordinates },
      } = routeMultiLine

      const nearestPoints = coordinates
        .filter(item => item.length > 1)
        .map(coords => {
          return turf.nearestPointOnLine(
            turf.lineString(coords),
            pointCoordinates
          )
        })
      const linesDistance = nearestPoints.map(point => point.properties.dist)
      const nearestIndex = getFromArrayMinValueIndex(linesDistance)

      return {
        lineIndex: nearestIndex,
        nearestPoint: nearestPoints[nearestIndex],
      }
    },
    [routeMultiLine]
  )

  const breakLine = useCallback(
    (lineIndex, pointId, pointCoordinates) => {
      const {
        properties: { msNeighbors },
        geometry: { coordinates },
      } = routeMultiLine
      // get line to divide
      const lineToDivide = msNeighbors.find(line =>
        line.find(item => item.line === lineIndex)
      )
      const endPointId = lineToDivide[1].point
      const endPoint = routeGeojson.features.find(
        item => item.properties.id === endPointId
      )
      // update existing line with the new point
      lineToDivide[1] = {
        line: lineIndex,
        point: pointId,
      }
      routeMultiLine.geometry.coordinates[lineIndex][1] = pointCoordinates

      // get the last line index, to check is this the start or the end line
      const lastIndex = getCurrentIndex()
      const neighbors = [
        {
          line: lastIndex,
          point: pointId,
        },
        {
          line: lastIndex,
          point: endPointId,
        },
      ]
      const endCoordinates = [pointCoordinates, endPoint.geometry.coordinates]

      if (coordinates[lastIndex]) {
        // this is start of the line
        routeMultiLine.properties.msNeighbors = insertAtPosition(
          msNeighbors,
          neighbors,
          lastIndex
        )
        routeMultiLine.geometry.coordinates = insertAtPosition(
          coordinates,
          endCoordinates,
          lastIndex
        )
        // if line starts from the new point on the line, we need increase line index inside existing line object
        routeMultiLine.properties.msNeighbors[lastIndex + 1][0].line =
          lastIndex + 1
        setPointProperty(pointId, 'active', true)

        updateNeighborsAfterLineBreak({
          lastPoint,
          lineToDivide,
          newLineIndex: lastIndex,
          dividedLineIndex: lineIndex,
          currentRoute,
          msNeighbors: routeMultiLine.properties.msNeighbors,
          setCurrentRoute,
        })
      } else {
        // this is end line
        msNeighbors.push(neighbors)
        coordinates.push(endCoordinates)

        updateNeighborsAfterLineBreak({
          lastPoint,
          lineToDivide,
          newLineIndex: lastIndex,
          dividedLineIndex: lineIndex,
          currentRoute,
          msNeighbors,
          setCurrentRoute,
        })
        // cancel draw the dotted line
        lastPoint.id = false
        drawingLineGeometry.coordinates = []
      }
      setPointProperty(pointId, 'connecting', true)
    },
    [
      routeMultiLine,
      routeGeojson.features,
      getCurrentIndex,
      setPointProperty,
      updateNeighborsAfterLineBreak,
      lastPoint,
      currentRoute,
      setCurrentRoute,
      drawingLineGeometry,
    ]
  )

  const handleClickOnMap = useCallback(
    event => {
      if (!currentMap.startDrawWaypoint) {
        return false
      }

      let lastCoord = [event.lngLat.lng, event.lngLat.lat]
      const features = currentMap.queryRenderedFeatures(event.point, {
        layers: ['nearmotion-routes-points'],
      })

      const selectedLine = currentMap.queryRenderedFeatures(event.point, {
        layers: ['nearmotion-routes-lines'],
      })
      let pointId
      removePointActive()

      if (features?.length) {
        // Reuse/connect point with already exist (The click was on the existing point)

        // id of clicked point
        pointId = features.find(some => some).properties.id
        const currentPoint = routeGeojson.features.find(
          item => item.properties.id === pointId
        )
        setPointProperty(pointId, 'active', true)

        linkPoints(currentPoint)
      } else if (selectedLine?.length) {
        // Put point on the line
        // get nearest distance from point to the line
        const { lineIndex, nearestPoint } = getNearestLinePoint(lastCoord)
        lastCoord = nearestPoint.geometry.coordinates
        pointId = createPoint(lastCoord, selectedLine.length)
        breakLine(lineIndex, pointId, lastCoord)
      } else {
        // Create new point, point can be first, or next after the first
        pointId = createPoint(lastCoord)
        setPointProperty(pointId, 'active', true)
      }
      updateRouteSource()
    },
    [
      currentMap,
      routeGeojson,
      breakLine,
      createPoint,
      getNearestLinePoint,
      linkPoints,
      removePointActive,
      setPointProperty,
      updateRouteSource,
    ]
  )
  // -------- ON CLICK END ----------

  // -------- ON MOVE START ----------
  const drawDottedLine = useCallback(
    currentCoordinates => {
      if (drawingLineGeometry.coordinates.length) {
        drawingLineGeometry.coordinates[1] = currentCoordinates
      }
    },
    [drawingLineGeometry]
  )

  const showNearestLinePoint = useCallback(
    (currentCoordinates, hasHoverLine) => {
      if (hasHoverLine) {
        drawingPointGeometry.coordinates = getNearestPointOnLine(
          currentCoordinates
        )
      } else {
        drawingPointGeometry.coordinates = []
      }
    },
    [drawingPointGeometry, getNearestPointOnLine]
  )

  const handleMoveOnMap = useCallback(
    event => {
      let features

      if (window.shiftHoldEvent) {
        features = currentMap.queryRenderedFeatures(event.point, {
          layers: ['nearmotion-routes-points'],
        })
        const pointId = features?.[0]?.properties?.id

        if (pointId && window.lastLoggedPoint !== pointId) {
          window.lastLoggedPoint = pointId
          console.log(`Point ID: ${pointId} `)
        }
      }

      if (!currentMap.startDrawWaypoint) {
        return false
      }

      features =
        features ||
        currentMap.queryRenderedFeatures(event.point, {
          layers: ['nearmotion-routes-points'],
        })
      const lineFeatures = currentMap.queryRenderedFeatures(event.point, {
        layers: ['nearmotion-routes-lines'],
      })
      const currentCoordinates = [event.lngLat.lng, event.lngLat.lat]
      showNearestLinePoint(
        currentCoordinates,
        lineFeatures[0]?.layer && !features?.length
      )

      drawDottedLine(currentCoordinates)
      updateDrawSource()
    },
    [currentMap, drawDottedLine, showNearestLinePoint, updateDrawSource]
  )
  // -------- ON MOVE END ----------

  // remove only the last line, that have only the start and haven't the end coordinates
  const removeLastLine = useCallback(() => {
    const { msNeighbors } = routeMultiLine.properties
    const { coordinates } = routeMultiLine.geometry
    const lineIndex = msNeighbors.findIndex(item => item.length === 1)

    if (lineIndex >= 0) {
      coordinates.splice(lineIndex, 1)
      msNeighbors?.splice(lineIndex, 1)
    }
  }, [routeMultiLine])

  // update neighbors indexes according existing lines
  const updateNeighbors = useCallback(
    indexToRemove => {
      const { msNeighbors } = routeMultiLine.properties
      msNeighbors.forEach((item, index) => {
        item.forEach((lineItem, lineI) => {
          if (lineItem.line > indexToRemove) {
            item[lineI].line = lineItem.line - 1
          }
        })
        msNeighbors[index] = item
      })
    },
    [routeMultiLine]
  )

  // remove connecting style property from the point with only one neighbor
  const cleanUpConnecting = useCallback(() => {
    const { msNeighbors } = routeMultiLine.properties

    routeGeojson.features
      .filter(item => item.geometry.type === 'Point')
      .forEach(item => {
        const pointId = item.properties.id
        const neighbors = msNeighbors.filter(line =>
          line.find(lineItem => lineItem.point === pointId)
        )

        setPointProperty(pointId, 'connecting', neighbors.length !== 1)
      })
  }, [routeMultiLine.properties, routeGeojson.features, setPointProperty])

  const removeLine = useCallback(
    pointId => {
      const { msNeighbors } = routeMultiLine.properties
      const { coordinates } = routeMultiLine.geometry
      msNeighbors.forEach((line, index) => {
        const filteredLine = line.filter(item => item.point === pointId)
        if (filteredLine.length) {
          const lineIndex = filteredLine[0].line
          updateNeighbors(lineIndex)
          coordinates.splice(lineIndex, 1)
          // console.log('removed line:', filteredLine[0].line)
          // console.log('------')
          // console.log('line end point: ', pointId)
          // console.log('line end point: ', pointId)
          // mark line for remove
          msNeighbors[index] = []
        }
      })
      routeMultiLine.properties.msNeighbors = msNeighbors.filter(
        item => item.length
      )
    },
    [routeMultiLine.properties, routeMultiLine.geometry, updateNeighbors]
  )

  const getSinglePoint = useCallback(() => {
    let singlePointId

    const { msNeighbors } = routeMultiLine.properties
    const neighbor = msNeighbors.find(item => item.length === 1)

    if (neighbor?.length > 0) {
      const pointId = neighbor[0].point
      const isSinglePoint =
        msNeighbors.find(line => line.find(item => item.point === pointId))
          .length === 1
      if (isSinglePoint) {
        singlePointId = pointId
      }
    }

    return singlePointId
  }, [routeMultiLine])

  const removePointFromMap = useCallback(
    pointId => {
      removeVirtualPoint({
        pointId,
        currentRoute,
      })

      // remove point from geojson
      routeGeojson.features = routeGeojson.features.filter(
        item => item.properties.id !== pointId
      )
    },
    [removeVirtualPoint, currentRoute, routeGeojson]
  )

  const cleanUpSinglePoints = useCallback(() => {
    const { msNeighbors } = routeMultiLine.properties

    routeGeojson.features.forEach(feature => {
      if (feature.geometry.type === 'Point') {
        const pointId = feature.properties.id
        const pointNeighbors = msNeighbors.filter(line =>
          line.find(item => item.point === pointId)
        )

        if (pointNeighbors < 2 || pointNeighbors[0]?.length < 2) {
          removePointFromMap(pointId)
          removeLine(pointId)
        }
      }
    })
  }, [
    routeMultiLine.properties,
    routeGeojson.features,
    removePointFromMap,
    removeLine,
  ])

  const stopDrawLine = useCallback(() => {
    if (!lastPoint.id) return false

    const singlePointId = getSinglePoint()
    if (singlePointId) {
      removePointFromMap(singlePointId)
    }

    removeLastLine()
    cleanUpConnecting()
    lastPoint.id = false
    drawingLineGeometry.coordinates = []
    drawingPointGeometry.coordinates = []
    removePointActive()
    setWayWasAdded(true)
  }, [
    lastPoint,
    getSinglePoint,
    removeLastLine,
    cleanUpConnecting,
    drawingLineGeometry,
    drawingPointGeometry,
    removePointActive,
    setWayWasAdded,
    removePointFromMap,
  ])

  const cancelAddWayPoint = useCallback(() => {
    stopDrawLine()
    setStartAddWay(false)
    dispatch(setActiveDrawTool({ activeDrawTool: '' }))
    setActiveDrawTool(false)

    startAddStairs.value = false
    startAddElevator.value = false
    setStartAddStairs({ ...startAddStairs })
    setStartAddElevator({ ...startAddElevator })
    updateRouteSource()
    updateDrawSource()
    currentMap.startDrawWaypoint = false
    currentMap.getCanvas().style.cursor = 'auto'
  }, [
    currentMap,
    dispatch,
    setActiveDrawTool,
    setStartAddElevator,
    setStartAddStairs,
    setStartAddWay,
    startAddElevator,
    startAddStairs,
    stopDrawLine,
    updateDrawSource,
    updateRouteSource,
  ])

  const startDraw = useCallback(
    (action, type = '') => {
      setStartAddWay(action)
      if (type !== 'stairs') {
        startAddStairs.value = false
      }
      if (type !== 'elevator') {
        startAddElevator.value = false
      }

      setStartAddPoi(false)
      currentMap.startDrawWaypoint = action
      lastPoint.type = type
      mapDraw.changeMode('static', {})
      setShowRoutes(true)
      currentMap.getCanvas().style.cursor = 'crosshair'
    },
    [
      setStartAddWay,
      startAddStairs,
      startAddElevator,
      setStartAddPoi,
      currentMap,
      lastPoint,
      mapDraw,
      setShowRoutes,
    ]
  )

  const removePoint = useCallback(
    pointId => {
      stopDrawLine()
      // console.log('selected point to remove: ', pointId)
      removePointFromMap(pointId)
      removeLine(pointId)

      cleanUpSinglePoints()
      cleanUpConnecting()
    },
    [
      cleanUpConnecting,
      cleanUpSinglePoints,
      removeLine,
      removePointFromMap,
      stopDrawLine,
    ]
  )

  const addElevator = useCallback(() => {
    const value = !startAddElevator.value
    startAddElevator.value = value
    startDraw(value, 'elevator')
    setStartAddElevator({ ...startAddElevator })
  }, [startAddElevator, startDraw, setStartAddElevator])

  const addStairs = useCallback(() => {
    const value = !startAddStairs.value
    startAddStairs.value = value
    setStartAddStairs({ ...startAddStairs })
    startDraw(value, 'stairs')
  }, [startAddStairs, setStartAddStairs, startDraw])

  return {
    startDraw,
    cancelAddWayPoint,
    addElevator,
    addStairs,
  }
}
