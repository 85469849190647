import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components/macro'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import { ReactComponent as RoutesTwoWayImage } from 'src/assets/images/svg/map/routesTwoWay.svg'
import { ReactComponent as RoutesOneWayImage } from 'src/assets/images/svg/map/routesOneWay.svg'
import { ReactComponent as PoiImage } from 'src/assets/images/svg/map/poi.svg'
import { ReactComponent as StairsImage } from 'src/assets/images/svg/map/stairs.svg'
import { ReactComponent as MarkerImage } from 'src/assets/images/svg/map/marker.svg'
import { ReactComponent as ArrowImage } from 'src/assets/images/svg/arrow-down.svg'

import { useMap } from 'src/modules/map'
import { useBeacons } from 'src/pages/MapStudio/features/Beacons'
import { useAbility } from 'src/hooks/useAbility'
import { clearLayers } from 'src/pages/MapStudio/controllers/clearLayers'
import { useBuildings } from 'src/modules/buildings'
import { listenForOutsideClicks } from 'src/utils/clickOutside'

import { useMapStudio } from './controllers/MapStudioController'
import { useRoute } from './features/Route'
import { usePois } from './features/Poi'

function MapComponent(props) {
  const history = useHistory()
  const params = useParams()
  const { buildingId, floorId } = params
  const [isInitiated, setIsInitiated] = useState(false)
  const [loadTimeout, setLoadTimeout] = useState()

  const { building } = useBuildings()

  const { map, mapZoom, mapDraw, mapContainer } = useMap()
  const {
    startAddWay,
    startSelectPoi,
    setStartSelectPoi,
    startAddPoi,
    startAddElevator,
    startAddStairs,
    setShowRoutes,
    showRoutes,
    showBeacon,
    setShowBeacon,
    startAddBeacon,
    setShowBeacons,
    showBeacons,
    setStartAddWay,
    setStartAddElevator,
    setStartAddStairs,
    setStartAddPoi,
    isShowDirection,
    isShowPointId,
    activeTool: selectedTool,
  } = useMapStudio()

  const { addPolygon } = usePois({ isInitiated })

  const {
    addBeacon,
    cancelAddBeacon,
    renderPopup: renderBeaconsPopup,
  } = useBeacons({
    isInitiated,
  })

  const { cancelAddWayPoint, startDraw, addElevator, addStairs } = useRoute({
    isInitiated,
  })

  const {
    floor: { manage },
  } = useAbility()

  // for close outside
  const menuRef = useRef(null)
  const [listening, setListening] = useState(false)
  //

  const [navigationTypesOpen, setNavigationTypesOpen] = useState(false)

  const [activeTool, setActiveTool] = useState(selectedTool.value)

  const [isTwoWayActive, setIsTwoWayActive] = useState(
    startAddWay && activeTool === 'twoWay'
  )
  const [isOneWayActive, setIsOneWayActive] = useState(
    startAddWay && activeTool === 'oneWay'
  )

  const [isStartAddStairs, setIsStartAddStairs] = useState(startAddStairs.value)
  const [isStartAddElevator, setIsStartAddElevator] = useState(
    startAddStairs.value
  )

  const [showPointsId, setShowPointsId] = useState(isShowPointId.value)
  const [showRoutesDirections, setShowRoutesDirections] = useState(
    isShowDirection.value
  )

  useEffect(() => {
    setIsStartAddElevator(startAddElevator.value)
  }, [startAddElevator.value])

  useEffect(() => {
    setIsStartAddStairs(startAddStairs.value)
  }, [startAddStairs.value])

  useEffect(() => {
    setActiveTool(selectedTool.value)
  }, [selectedTool.value])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    listenForOutsideClicks(
      listening,
      setListening,
      menuRef,
      setNavigationTypesOpen
    )
  )

  useEffect(() => {
    setIsTwoWayActive(
      startAddWay &&
        activeTool === 'twoWay' &&
        !startAddStairs.value &&
        !startAddElevator.value
    )
  }, [startAddWay, activeTool, startAddElevator, startAddStairs])

  useEffect(() => {
    setIsOneWayActive(
      startAddWay &&
        activeTool === 'oneWay' &&
        !startAddStairs.value &&
        !startAddElevator.value
    )
  }, [startAddWay, activeTool, startAddElevator, startAddStairs])

  // Needs to be in a separate callback
  useEffect(() => {
    return () => {
      clearTimeout(loadTimeout)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (!mapContainer?.current || !mapDraw || !mapDraw?.delete) return false

      mapDraw.deleteAll()
    }
  }, [mapDraw])

  useEffect(() => {
    if (map) {
      return function cleanup() {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (!mapContainer?.current) return false

        clearLayers(map, /^nearmotion-(floor|layer)-.*/)
      }
    }
  }, [map])

  // Dont move!
  useEffect(() => {
    if (!isInitiated && mapDraw && mapZoom >= 18) {
      const timeout = setTimeout(() => {
        setIsInitiated(true)
      }, 1300)
      setLoadTimeout(timeout)
    }
  }, [isInitiated, mapDraw, mapZoom])

  const cancelAll = useCallback(() => {
    setStartSelectPoi(false)
    setStartAddWay(false)
    // setStartAddElevator(false)
    // setStartAddStairs(false)

    startAddStairs.value = false
    startAddElevator.value = false
    setStartAddStairs({ ...startAddStairs })
    setStartAddElevator({ ...startAddElevator })
    setStartAddPoi(false)
    map.startDrawWaypoint = true
    mapDraw?.changeMode('static', {})
    cancelAddBeacon()
    cancelAddWayPoint()
  }, [
    cancelAddBeacon,
    cancelAddWayPoint,
    map,
    mapDraw,
    setStartAddElevator,
    setStartAddPoi,
    setStartAddStairs,
    setStartAddWay,
    setStartSelectPoi,
    startAddElevator,
    startAddStairs,
  ])

  const handleAddPoi = useCallback(() => {
    cancelAll()
    addPolygon()
  }, [cancelAll, addPolygon])

  const handleSelectPoi = useCallback(() => {
    const isStatic = mapDraw.getMode() === 'static'
    cancelAll()
    setStartSelectPoi(isStatic)
    mapDraw.changeMode(!isStatic ? 'static' : 'simple_select', {})
  }, [cancelAll, mapDraw, setStartSelectPoi])

  const handleAddBeacon = useCallback(() => {
    cancelAll()
    if (!startAddBeacon) {
      addBeacon()
    }
  }, [cancelAll, startAddBeacon, addBeacon])

  const handleAddSingleWayRoute = useCallback(() => {
    setNavigationTypesOpen(false)
    if (
      !startAddWay ||
      activeTool === 'twoWay' ||
      isStartAddStairs ||
      isStartAddElevator
    ) {
      startAddElevator.value = false
      startAddStairs.value = false
      setStartAddStairs({ ...startAddStairs })
      setStartAddElevator({ ...startAddElevator })

      selectedTool.value = 'oneWay'
      setActiveTool('oneWay')
      startDraw(true)
    } else {
      cancelAll()
    }
  }, [
    startAddWay,
    activeTool,
    isStartAddStairs,
    isStartAddElevator,
    startAddElevator,
    startAddStairs,
    setStartAddStairs,
    setStartAddElevator,
    selectedTool,
    startDraw,
    cancelAll,
  ])

  const handleAddTwoWayRoute = useCallback(() => {
    setNavigationTypesOpen(false)
    if (
      !startAddWay ||
      activeTool === 'oneWay' ||
      isStartAddStairs ||
      isStartAddElevator
    ) {
      startAddElevator.value = false
      startAddStairs.value = false
      setStartAddStairs({ ...startAddStairs })
      setStartAddElevator({ ...startAddElevator })

      selectedTool.value = 'twoWay'
      setActiveTool('twoWay')
      startDraw(true)
    } else {
      cancelAll()
    }
  }, [
    startAddWay,
    activeTool,
    isStartAddStairs,
    isStartAddElevator,
    startAddElevator,
    startAddStairs,
    setStartAddStairs,
    setStartAddElevator,
    selectedTool,
    startDraw,
    cancelAll,
  ])

  const handleAddElevator = useCallback(() => {
    cancelAll()
    addElevator()
  }, [cancelAll, addElevator])

  const handleAddStairs = useCallback(() => {
    cancelAll()
    addStairs()
  }, [cancelAll, addStairs])

  const handleShowRoutes = useCallback(() => {
    setShowRoutes(!showRoutes)
  }, [setShowRoutes, showRoutes])

  const handleShowPointsId = () => {
    setShowPointsId(!showPointsId)
    isShowPointId.value = !showPointsId
  }
  const handleShowRoutsDirections = () => {
    setShowRoutesDirections(!showRoutesDirections)
    isShowDirection.value = !showRoutesDirections
  }

  const handleShowBeacons = useCallback(() => {
    if (showBeacon) {
      history.replace(`/buildings/${buildingId}/floors/${floorId}/`)
      setShowBeacon(false)
      setShowBeacons(true)
      setShowRoutes(true)

      return
    }
    setShowBeacons(!showBeacons)
  }, [
    buildingId,
    floorId,
    history,
    setShowBeacon,
    setShowBeacons,
    setShowRoutes,
    showBeacon,
    showBeacons,
  ])

  const handleNavigationTypesOpen = () => {
    setNavigationTypesOpen(!navigationTypesOpen)
  }

  return (
    <Wrapper className={props.className}>
      <GlobalStyle />
      <MapTools>
        {!building?.jibestream_id && (
          <>
            <Routes ref={menuRef}>
              <DropDown isOpen={navigationTypesOpen}>
                <ButtonRoutes
                  disabled={!isInitiated || !manage}
                  onClick={handleAddTwoWayRoute}
                  active={isTwoWayActive}
                  selected={activeTool === 'twoWay'}
                >
                  <RoutesTwoWayImage />
                  Routes
                </ButtonRoutes>
                <ButtonRoutes
                  disabled={!isInitiated || !manage}
                  onClick={handleAddSingleWayRoute}
                  active={isOneWayActive}
                  selected={activeTool === 'oneWay'}
                >
                  <RoutesOneWayImage />
                  Routes
                </ButtonRoutes>
              </DropDown>
              <Row isOpen={navigationTypesOpen}>
                <Arrow
                  onClick={handleNavigationTypesOpen}
                />
              </Row>
            </Routes>
            <Button
              disabled={!isInitiated || !manage}
              onClick={handleAddPoi}
              active={startAddPoi}
            >
              <PoiImage />
              Create POI
            </Button>
            <Button
              disabled={!isInitiated}
              onClick={handleSelectPoi}
              active={startSelectPoi}
            >
              <PoiImage />
              Select POI
            </Button>
            <Elevators
              disabled={!isInitiated || !manage}
              onClick={handleAddElevator}
              active={isStartAddElevator}
            >
              <StairsImage />
              Add Elevators
            </Elevators>
            <Stairs
              disabled={!isInitiated || !manage}
              onClick={handleAddStairs}
              active={isStartAddStairs}
            >
              <StairsImage />
              Add Stairs
            </Stairs>
          </>
        )}

        <Button
          disabled={!isInitiated}
          onClick={handleAddBeacon}
          active={startAddBeacon}
        >
          <MarkerImage />
          Place Beacons
        </Button>
      </MapTools>
      <LayersVisibility>
        {!building?.jibestream_id && (
          <Button>
            <label htmlFor="routes">
              <input
                type="checkbox"
                id="routes"
                checked={showRoutes}
                onChange={handleShowRoutes}
              />{' '}
              Routes
            </label>
          </Button>
        )}

        <Button>
          <ShowBeaconsLabel showBeacon={showBeacon} htmlFor="beacons">
            <ShowBeacons
              type="checkbox"
              id="beacons"
              showBeacon={showBeacon}
              defaultChecked={showBeacons}
              onChange={handleShowBeacons}
            />{' '}
            Beacons
          </ShowBeaconsLabel>
        </Button>

        <Button>
          <label htmlFor="showPointsId">
            <input
              type="checkbox"
              id="showPointsId"
              defaultChecked={showPointsId}
              onChange={handleShowPointsId}
            />{' '}
            Point ID
          </label>
        </Button>
        <Button>
          <label htmlFor="routeDirections">
            <input
              type="checkbox"
              id="routeDirections"
              defaultChecked={showRoutesDirections}
              onChange={handleShowRoutsDirections}
            />{' '}
            Route Directions
          </label>
        </Button>
      </LayersVisibility>
      {renderBeaconsPopup()}
    </Wrapper>
  )
}

export const MapStudio = styled(MapComponent)``

const Wrapper = styled.div`
  position: absolute;
  width: 100vw;
`

const MapTools = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  gap: 10px;
  height: 62px;
  left: 375px;
  top: 30px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(240, 242, 247, 0.3);
  backdrop-filter: blur(3px);
  border-radius: 7.45px;
  z-index: 1;
`

const LayersVisibility = styled(MapTools)`
  display: flex;
  left: initial;
  right: 25px;
  width: auto;
  max-width: 200px;
  flex-direction: column;
  padding: 20px;
  height: min-content;
  align-items: start;

  label {
    cursor: pointer;
    display: flex;

    input {
      margin: 0;
      margin-right: 10px;
      border-radius: 10px;
    }
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;

  svg {
    height: 30px;
    margin-right: 10px;
  }

  ${props =>
    props.active &&
    `
    svg {
      fill: #5468ff;
      stroke: #5468ff;
    }
  `}
  &:hover {
    svg {
      transition-duration: 0.3s;
      transform: scale(1.2);
      fill: #5468ff;
      stroke: #5468ff;
    }
  }
`
const Stairs = styled(Button)`
  &:hover {
    svg {
      fill: #00d9fc;
      opacity: 1;
    }
  }

  svg {
    fill: #00d9fc;
    opacity: 0.5;
  }

  ${props =>
    props.active &&
    `svg {
      opacity: 1;
      fill: #00d9fc;
    }
  `}
`

const Elevators = styled(Button)`
  &:hover {
    svg {
      fill: #fcc500;
      opacity: 1;
    }
  }

  svg {
    fill: #fcc500;
    opacity: 0.5;
  }

  ${props =>
    props.active &&
    `svg {
      opacity: 1;
      fill: #fcc500;
    }
  `}
`

const ShowBeacons = styled.input``

const ShowBeaconsLabel = styled.label`
  position: relative;
  ${props =>
    props.showBeacon &&
    `
     ${ShowBeacons}{
        opacity: 0;
     }
     &:after{
      content: "";
      display: block;
      position: absolute;
      top: 1px;
      left: 0;
      border: 2px solid white;
      box-shadow: 0px 0px 0px 1px #333;
      border-radius: 1px;
      width: 12px;
      height: 12px;
      background: #0275ff;
     }
  `}
`

const GlobalStyle = createGlobalStyle`
    .mapboxgl-popup-content {
        p {
            margin-top: 5px;
        }
    }

    .engagement-link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 148px;
        font-size: 13px;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        text-decoration: none;
        color: white;
        background-color: #5468FF;

        &.engagement-remove-link {
            color: white;
            background-color: #ff6355;
        }
    }

`
const Routes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  label {
    margin: 0;
    white-space: nowrap;
  }
`
const Arrow = styled(ArrowImage)`
  padding: 0 10px;
  width: 30px;
  height: 60px;
  cursor: pointer;

  &:hover {
    fill: #5468ff;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
    ${props =>
      props.isOpen && `
      ${Arrow}{
        fill: #5468ff;
      }`
    }
`
const ButtonRoutes = styled(Button)`
  display: none;
  min-height: 60px;
  min-width: 87px;

  ${props =>
    props.selected &&
    `
      display: flex;
    `}
`

const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 60px;
  top: 0;
  position: relative;
  justify-content: start;
  gap: 1px;
  ${props =>
    props.isOpen &&
    `
      display: flex;
      top: 0;
      align-items: start;
      ${ButtonRoutes}{
          min-height: 60px;
          display: flex;
          &:nth-of-type(2){
              background: rgba(255, 255, 255, 0.5);
              -webkit-backdrop-filter: blur(3px);
              backdrop-filter: blur(3px);    
              border-bottom-left-radius: 8px;react-onclickoutside
              border-bottom-right-radius: 8px;
          }
      }
    `}
`


