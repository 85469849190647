export function reduceRoutes(props) {

  const {
    createDirectionArrowPoint,
    routes,
    routeGeojson
  } = props;

  routes.reduce((hash, point) => {
    hash[point.point_id] = JSON.parse(JSON.stringify(point.neighbors));
    point.directionPoints = []; // Initialize directionPoints array for each point

    return hash;
  }, {});

  const multiLine = routeGeojson?.features?.find(item => item.geometry.type === 'MultiLineString');

  let lineIndex = 0;

  const updateGeojson = (currentPoint, startPointId) => {
    const hasThisPoint = multiLine.properties.msNeighbors.findIndex(neighborsItem =>
      neighborsItem.findIndex(item => item.point === currentPoint.point_id) !== -1 &&
      neighborsItem.findIndex(item => item.point === startPointId) !== -1
    );

    if (hasThisPoint !== -1) {
      return false;
    }

    multiLine.properties.msNeighbors.push([
      { line: lineIndex, point: startPointId },
      { line: lineIndex, point: currentPoint.point_id },
    ]);

    lineIndex++;

    const secondPoint = routes.find(point => point.point_id === startPointId);
    const lineCoordinates = [
      [secondPoint.longitude, secondPoint.latitude],
      [currentPoint.longitude, currentPoint.latitude],
    ];

    multiLine.geometry.coordinates.push(lineCoordinates);
  };

  const multiLinePoints = {};
  routes.forEach(point => {
    const pointNeighbors = point.neighbors || [];

    pointNeighbors.forEach(neighborId => {
      const neighborPoint = routes.find(p => p.point_id === neighborId);
      if (neighborPoint) {
        const startEndPoint = `${neighborId}${point.point_id}`;
        const endStartPoint = `${point.point_id}${neighborId}`;

        if (!multiLinePoints[startEndPoint] && !multiLinePoints[endStartPoint]) {
          multiLinePoints[startEndPoint] = [neighborId, point.point_id];
          updateGeojson(neighborPoint, point.point_id);

          // Create the virtual point
          createDirectionArrowPoint({ point, neighborPoint, routeGeojson });
        }
      }
    });
  });

  // Add original route points
  routes.forEach(point => {
    routeGeojson.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [point.longitude, point.latitude],
      },
      properties: {
        id: point.point_id,
        kind: point.kind,
        connecting: point.neighbors.length > 1,
        hasNeighbors: Boolean(point.neighbors.length),
        neighbors: point.neighbors,
        pointCoordinates: [point.longitude, point.latitude].toString() + point.point_id,
      },
    });
  });

  return routeGeojson;
}
