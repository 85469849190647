export const useRouteNavigationArrows = () => {
  const clearPreviousLayers = props => {
    const { currentMap, matcher } = props
    currentMap.getStyle().layers.forEach(layer => {
      if (layer.id.match(matcher)) {
        currentMap.removeLayer(layer.id)
      }
    })
  }

  const showPointsId = props => {
    cleanPointsId({ currentMap: props.currentMap })
    props.currentMap.addLayer({
      id: `nearmotion-pointid-layer`,
      type: 'symbol',
      source: 'nearmotion-routesGeoJson',
      layout: {
        'text-field': ['get', 'id'],
        'text-size': 12,
        'symbol-placement': 'point',
        'text-offset': ['literal', [3.3, 1]],
        'text-allow-overlap': true,

      },
      filter: ['!=', ['get', 'kind'], 'direction'],
    })
  }


  const loadRouteNavigation = props => {
    cleanRouteNavigation({ currentMap: props.currentMap })

    props.currentMap.addLayer({
      id: 'nearmotion-routes-direction-arrows',
      type: 'symbol',
      source: 'nearmotion-routesGeoJson',
      layout: {
        'text-field': ['get', 'arrowDirection'], // Get the arrow direction from properties
        'text-size': 32, // Adjust text size as needed
        'text-allow-overlap': true,
        'symbol-placement': 'point',
        'text-rotate': ['get', 'angle'], // Rotate text based on calculated angle
        'text-offset': [-0.1, -0.1], // Adjust text offset if necessary
      },
      paint: {
        'text-color': '#FF0000', // Set color for the text

      },
      filter: ['in', ['get', 'kind'], 'direction'],
    });
  }

  const createDirectionArrowPoint = (props) => {
    const { point, neighborPoint, routeGeojson } = props
    // Calculate midpoint
    const midpoint = [
      (parseFloat(point.longitude) + parseFloat(neighborPoint.longitude)) / 2,
      (parseFloat(point.latitude) + parseFloat(neighborPoint.latitude)) / 2
    ];

    // Determine direction for arrows
    const isBidirectional = neighborPoint.neighbors.includes(point.point_id);
    const arrowDirection = isBidirectional ? '↔' : '→';

    // Calculate the angle based on coordinates
    const angle = Math.atan2(
      parseFloat(neighborPoint.latitude) - parseFloat(point.latitude),
      parseFloat(neighborPoint.longitude) - parseFloat(point.longitude)
    ) * (-180 / Math.PI); // Convert to degrees

    // Add hidden point for direction
    routeGeojson.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: midpoint,
      },
      properties: {
        id: `${point.point_id}-${neighborPoint.point_id}-hidden`,
        kind: 'direction',
        arrowDirection,
        angle, // Use the calculated angle directly
      },
    });

    // Add this midpoint to both points' directionPoints
    point.directionPoints.push(midpoint);
    neighborPoint.directionPoints.push(midpoint);
  };

  const cleanRouteNavigation = props => {
    const { currentMap } = props
    clearPreviousLayers({ currentMap, matcher: /^nearmotion-routes-direction-arrows.*/ })
  }

  const cleanPointsId = props => {
    const { currentMap } = props
    clearPreviousLayers({ currentMap, matcher: /^nearmotion-pointid-layer.*/ })
  }

  return {
    loadRouteNavigation,
    cleanPointsId,
    cleanRouteNavigation,
    createDirectionArrowPoint,
    showPointsId,
  }
}

export default useRouteNavigationArrows
